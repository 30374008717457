<template>
  <section class="invoice-preview-wrapper">
    <b-row class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-form @submit.prevent>
          <b-overlay
            :show="orderOverlay"
            rounded="sm"
          >
            <b-card
              no-body
              class="invoice-preview-card"
              ref="document"
              id="ordenCompra"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">

                <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                  <!-- Header: Left Content -->
                  <div>
                    <div class="logo-wrapper">
                      <logo />
                      <h3 class="text-primary invoice-logo">
                        Orden de Compra
                      </h3>
                    </div>
                      <h3 class="">
                        {{ brokerData.companyName }}
                      </h3>
                    <b-card-text class="mb-25">
                      {{ brokerData.address }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      {{ brokerData.country }}
                    </b-card-text>
                    <!-- <b-card-text class="mb-0">
                      +52 (811) 456 7891, +52 (551) 543 2198
                    </b-card-text> -->
                  </div>


                  <!-- Header: Right Content -->
                  <div class="invoice-number-date mt-md-0 mt-2">
                    <div v-if="invoiceData.idOrder" class="d-flex justify-content-end mb-1">
                      <span class="title">
                        <h4>
                          Orden
                        <span class="invoice-number">#{{invoiceData.idOrder}}</span>
                        </h4>
                      </span>
                    </div>
                    <div class="d-flex align-items-center mb-1">
                      <span class="title">
                        Fecha de creación:
                      </span>
                      <flat-pickr
                        v-model="invoiceData.creationDateString"
                        class="form-control invoice-edit-input"
                        v-on:input="detectUnsavedChange"
                        disabled
                      />
                      <!-- FIXME ajustar el configTime -->
                        <!-- :config="configTime" -->
                    </div>
                    <div class="d-flex align-items-center mb-1">
                      <span class="title">
                        Fecha de entrega estimada:
                      </span>
                      <flat-pickr
                        v-model="invoiceData.estimatedDateString"
                        class="form-control invoice-edit-input"
                        v-on:input="detectUnsavedChange"
                        disabled
                      />
                    </div>
                    <div class="d-flex align-items-center">
                      <span class="title">
                        Fecha de entregado:
                      </span>
                      <flat-pickr
                        v-model="invoiceData.deliveryDateString"
                        class="form-control invoice-edit-input"
                        v-on:input="detectUnsavedChange"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <!-- Invoice Client Details -->
              <b-card-body class="invoice-padding pt-0">
                <b-row class="invoice-spacing">

                  <!-- Col: Invoice To -->
                  <b-col
                    cols="12"
                    xl="6"
                    class="mb-lg-1"
                  >
                    <h6 class="mb-2">
                      Cliente:
                    </h6>

                    <!-- Select Client -->
                    <v-select
                      :options="companies"
                      label="companyName"
                      input-id="invoice-data-client"
                      :clearable="false"
                      v-on:input="detectUnsavedChange"
                      placeholder="Selecciona un proveedor"
                      @input='setClientData($event)'
                      v-model="invoiceData.companyData.companyName"
                      disabled
                    />
                  </b-col>

                  <!-- Col: Client Details -->
                  <b-col
                    xl="6"
                    cols="12"
                    class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block companyInfo"
                    style="justify-content: center!important;"
                  >
                  <!-- Selected Client -->
                    <div
                      class="mt-1"
                    >
                      <h6 class="mb-25">
                        {{ invoiceData.companyData.companyName }}
                      </h6>
                      <b-card-text class="mb-25">
                        {{ invoiceData.companyData.address }} {{ invoiceData.companyData.country }}
                      </b-card-text>
                      <b-card-text class="mb-25">
                        {{ invoiceData.companyData.contact }}
                      </b-card-text>
                      <b-card-text class="mb-0">
                        {{ invoiceData.companyData.companyEmail }}
                      </b-card-text>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Item Section -->
              <b-card-body 
                class="invoice-padding form-item-section"
              >
                <div
                  ref="form"
                  class="repeater-form"
                  :style="{height: 10}"
                >
                  <b-row
                    v-for="(item, indexItem) in invoiceData.items"
                    :key="indexItem"
                    ref="row"
                    class="pb-2"
                  >

                    <!-- Item Form -->
                    <!-- ? This will be in loop => So consider below markup for single item -->
                    <b-col cols="12">
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <div class="d-none d-lg-flex">
                        <b-row class="flex-grow-1 px-1">
                          <!-- Single Item Form Headers -->
                          <b-col
                            cols="12"
                            lg="6"
                          >
                            Item
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            Cantidad (kgs)
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            Precio
                          </b-col>
                          <b-col
                            cols="12"
                            lg="2"
                          >
                            Subtotal
                          </b-col>
                        </b-row>
                        <div class="form-item-action-col" />
                      </div>

                      <!-- Form Input Fields OR content inside bordered area  -->
                      <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                      <!-- Item ROW -->
                      <div class="d-flex border rounded">
                        <b-row class="flex-grow-1 p-1">
                          <!-- METAL -->
                          <b-col lg="6" sm="12" id="metal-select">
                            <label class="d-inline d-lg-none">Item</label>
                            <v-select
                              :options="items"
                              label="alias"
                              :clearable="false"
                              class="item-selector-title"
                              placeholder="Selecciona un item"
                              :reduce="alias => alias.idItem"
                              v-model="invoiceData.items[indexItem].idItem"
                              @input='getCommissions($event, indexItem)'
                              v-on:input="detectUnsavedChange"
                              disabled
                            >
                              <span slot="no-options">Por favor, selecciona un cliente primero.</span>
                            </v-select>
                          </b-col>
                          <!-- Cantidad estimada-->
                          <b-col lg="2" sm="12" class="item-data">
                            <label class="d-inline d-lg-none">Cantidad (kgs)</label>
                            <b-form-input
                              @keypress="restrictNumber($event)"
                              @input="updateInput(indexItem, null, $event, 'itemQuantity')"
                              type="number"
                              v-model="item.quantity"
                              disabled
                            />
                          </b-col>
                          <!-- Precio -->
                          <b-col lg="2" sm="12" class="item-data">
                            <label class="d-inline d-lg-none">Precio</label>
                            <b-form-input
                              @keypress="restrictNumber($event)"
                              @input="updateInput(indexItem, null, $event, 'itemPrice')"
                              type="number"
                              v-model="item.price"
                              disabled
                            />
                          </b-col>
                          <!-- Subtotal -->
                          <b-col lg="2" sm="12" class="item-data">
                            <label class="d-inline d-lg-none">Subtotal</label>
                            <b-form-input
                              disabled
                              :value="calculatedValues(indexItem, null, 'itemSubTotal')"
                            />
                          </b-col>
                          <b-col cols="12" lg="12">
                            <center style="margin-top: 2em">
                              <h5 v-if="invoiceData.items[indexItem].commissions.length > 0">Comisiones por item</h5>
                            </center>
                          </b-col>
                          <b-col lg="2" sm="0" id="spacer">
                          </b-col>
                          <b-col 
                            v-for="(commission, indexCommission) in invoiceData.items[indexItem].commissions"
                            :key="indexCommission"
                            cols="10" 
                            lg="10"
                          >
                            <b-row class="flex-grow-1 p-1">
                              <b-col lg="4" sm="12" class="item-commission">
                                <b-card-text>
                                <b>
                                  {{ commission.commission }}
                                </b>
                                </b-card-text>
                              </b-col>
                              <b-col lg="4" sm="12" class="item-commission">
                                <b-card-text>
                                  $ {{ commission.commissionPrice }} por kg
                                </b-card-text>
                              </b-col>
                              <b-col lg="4" sm="12" class="item-commission">
                                <b-card-text>
                                $ {{calculatedValues(indexItem, indexCommission, 'commission')}}
                                </b-card-text>
                              </b-col>
                            </b-row>
                          </b-col>
                        </b-row>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-card-body>

              <!-- Invoice Description: Total -->
              <b-card-body 
                class="invoice-padding pb-0"
              >
                <b-row>

                <!-- Spacer -->
                <hr class="invoice-spacing">

                  <!-- Col: Payment Type -->
                  <b-col
                    cols="12"
                    md="4"
                    class="mt-md-0 mt-3 d-flex align-items-center"
                  >
                  <b-card-body class="invoice-padding pt-0">
                    <b-form-group
                      label="Tipo de pago"
                      label-for="i-c-paymentType"
                    >
                      <v-select
                        :options="paymentTypes"
                        label="name"
                        input-id="invoice-data-paymentType"
                        :clearable="false"
                        v-on:input="detectUnsavedChange"
                        :reduce="name => name.id"
                        placeholder="Selecciona un tipo de pago"
                        @input='setPaymentType($event)'
                        v-model="invoiceData.paymentType"
                        disabled
                      />
                    </b-form-group>

                    <b-form-group
                      label="Dias de credito"
                      label-for="i-c-paymentType"
                      v-if="showCreditDays"
                    >
                      <b-form-input
                        @keypress="restrictNumber($event)"
                        type="number"
                        class="mb-2"
                        @input='setCreditDays($event)'
                        v-model="invoiceData.creditDays"
                        disabled
                      />
                    </b-form-group>

                    <b-form-group
                      label="Fecha estimada de pago"
                      label-for="i-c-paymentType"
                      v-if="showCreditDays"
                    >
                      <b-form-input
                        @keypress="restrictNumber($event)"
                        disabled
                        class="mb-2"
                        v-model="invoiceData.deadlinePaymentString"
                      />
                    </b-form-group>
                  </b-card-body>
                  </b-col>

                  <!-- Col: Status -->
                  <b-col
                    cols="12"
                    md="4"
                    class="mt-md-0 mt-3 d-flex align-items-center"
                  >
                  <b-card-body class="invoice-padding pt-0">
                    <b-form-group
                      label="Estatus del pago"
                      label-for="i-c-paymentType"
                    >
                      <v-select
                        :options="paymentStatus"
                        label="name"
                        input-id="invoice-data-paymentType"
                        :clearable="false"
                        v-on:input="detectUnsavedChange"
                        :reduce="name => name.id"
                        disabled
                        v-model="invoiceData.idPaymentStatus"
                        @input='setPartialPayment($event)'
                      />
                    </b-form-group>

                    <b-form-group
                      label="Cantidad abonada"
                      label-for="i-c-paymentType"
                      v-if="showPartialPayment"
                    >
                      <b-form-input
                        @keypress="restrictNumber($event)"
                        class="mb-2"
                        v-model="invoiceData.orderBalance"
                        disabled
                      />
                    </b-form-group>

                    <b-form-group
                      label="Estatus del envio"
                      label-for="i-c-paymentType"
                    >
                      <v-select
                        :options="shippingStatus"
                        label="name"
                        input-id="invoice-data-paymentType"
                        :clearable="false"
                        v-on:input="detectUnsavedChange"
                        :reduce="name => name.id"
                        v-model="invoiceData.idShippingStatus"
                        @input='setShippingStatus($event)'
                        disabled
                      />
                    </b-form-group>
                  </b-card-body>
                  </b-col>

                  <!-- Col: Total -->
                  <b-col
                    cols="12"
                    md="4"
                    class="mt-md-6 d-flex justify-content-center"
                  >
                    <div class="invoice-total-wrapper" style="max-width: 13rem">
                      <div
                        v-if="invoiceData.balancesCommissions.length > 0"
                      >
                        <b>Comisionistas</b>
                        <div 
                        v-for="(balance, indexBalance) in invoiceData.balancesCommissions"
                        :key="indexBalance"
                        class="invoice-total-item">
                          <p class="invoice-total-title">
                            {{ balance.name }}
                          </p>
                          <p class="invoice-total-amount">
                            $ {{calculatedValues(balance.idCommissionAgent, null, 'balancesCommissions')}}
                          </p>
                        </div>
                        <hr class="my-50">
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Subtotal:
                        </p>
                        <p class="invoice-total-amount">
                          $ {{calculatedValues(null, null, 'subTotal')}}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Costo logísitca:
                        </p>
                        <p class="invoice-total-amount">
                          $ {{calculatedValues(null, null, 'logistic')}}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Comisiones:
                        </p>
                        <p class="invoice-total-amount">
                          $ {{calculatedValues(null, null, 'commissions')}}
                        </p>
                      </div>
                      <!-- <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          IVA (16%):
                        </p>
                        <p class="invoice-total-amount">
                          $ {{calculatedValues(null, null, 'iva')}}
                        </p>
                      </div> -->
                      <div v-if="invoiceData.orderBalance > 0">
                        <hr class="my-50">
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">
                            <b>Abonado:</b>
                          </p>
                          <p class="invoice-total-amount">
                            -$ {{calculatedValues(null, null, 'abonado')}}
                          </p>
                        </div>
                        <div class="invoice-total-item">
                          <p class="invoice-total-title">
                            <b>Restante:</b>
                          </p>
                          <p class="invoice-total-amount">
                            $ {{calculatedValues(null, null, 'restante')}}
                          </p>
                        </div>
                      </div>
                      <hr class="my-50">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Total:
                        </p>
                        <p class="invoice-total-amount">
                          $ {{calculatedValues(null, null, 'total')}}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>

              <!-- Note -->
              <b-card-body 
                class="invoice-padding pt-0"
              >
                <span class="font-weight-bold">Nota: </span>
                <b-form-textarea
                  v-on:input="detectUnsavedChange"
                  v-model="invoiceData.note" 
                  disabled
                />
              </b-card-body>
            </b-card>
          </b-overlay>
        </b-form>
      </b-col>
      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="12"
        xl="12"
        class="invoice-actions mt-md-0 mt-2"
      >
        <b-card style="padding-top: 0.8em">
          <!-- Action Buttons -->
          <b-row>
            <!-- Button: Print -->
            <b-col md="6">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                class="mb-75"
                block
                @click="printInvoice"
              >
                Imprimir
              </b-button>
            </b-col>
            <!-- Button: Voucher -->
            <b-col md="6">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                block
                v-b-toggle.voucher-options
              >
                Enviar comprobante
              </b-button>
            </b-col>
          </b-row>
          <!-- Send Mail or SMS -->
          <b-collapse id="voucher-options">
            <b-row>
              <b-col lg="6" sm="12">
                <b-button 
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="mt-1"
                  block
                  @click="sendMail"
                >
                  <div v-if="mailVoucherStatus">
                    <feather-icon
                      icon="MailIcon"
                      size="14"
                    />
                    Volver a abrir correo
                  </div>
                  <div v-else>
                    <feather-icon
                      icon="MailIcon"
                      size="14"
                    />
                    Enviar por correo
                  </div>
                </b-button>
              </b-col>
              <b-col lg="6" sm="12">
                <b-button 
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  class="mt-1"
                  block
                  @click="sendSMS"
                  :disabled="smsVoucherStatus || smsSucess"
                >
                  <div v-if="smsSucess">
                    <feather-icon
                      icon="MessageSquareIcon"
                      size="14"
                    />
                    SMS Enviado
                  </div>
                  <div v-else-if="smsVoucherStatus">
                    <b-spinner small/>
                    &nbsp; Enviando SMS...
                  </div>
                  <div v-else>
                    <feather-icon
                      icon="MessageSquareIcon"
                      size="14"
                    />
                    Enviar por SMS
                  </div>
                </b-button>
              </b-col>
            </b-row>
          </b-collapse>
        </b-card>
      </b-col>
    </b-row>
    <!-- MODAL ALERTA -->
    <b-modal
      id="alertModal"
      title="Alerta"
      ok-title="Voy a revisarlo"
      ok-only
      centered
      no-close-on-backdrop
      hide-header-close
    >
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading">
          Faltan datos
        </h4>
        <div class="alert-body font-medium-2">
          <p>Favor de llenar todos los campos.</p>
        </div>
      </b-alert>
    </b-modal>
    <!-- MODAL CONFIRMACIÓN -->
    <b-modal
      id="confirmModal"
      title="Confirmar"
      ok-title="Guardar orden"
      cancel-title="Voy a revisarlo"
      cancel-variant="outline-secondary"
      @ok="saveData()"
      centered
      no-close-on-backdrop
      hide-header-close
    >
      <h3 style="margin-bottom: 1em">¿Seguro que deseas guardar esta orden?</h3>
      <p style="font-size: medium">Los datos ingresados no podrán ser editados.</p>
    </b-modal>
  </section>
</template>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>


<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import utils from '../../../utils/utils.js';
import { Spanish } from "flatpickr/dist/l10n/es.js"
flatpickr.localize(Spanish);
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'


// temp
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

import { BTabs, BTab, BButton, BForm, BFormGroup, BSpinner, BCollapse,
BFormInput, BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, 
BCardBody, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
BFormCheckbox, VBTooltip, BAlert, BFormTextarea, BPopover, VBToggle, BOverlay } from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'



export default {
  components: {

    BAlert,
    BTabs,
    BTab,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BCardBody,
    BFormTextarea,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
    VueApexCharts,
    BSpinner,
    BOverlay,
    BCollapse
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [heightTransition],
  data() {
    return {
      // Catalogs
      items: [],
      companies: [],
      providers: [],
      companyData: {},
      paymentTypes: [],
      shippingStatus: [
        {
          id: 5,
          name: 'Sin enviar'
        },
        {
          id: 6,
          name: 'En curso'
        },
        {
          id: 7,
          name: 'Entregado'
        }
      ],
      paymentStatus: [
        {
          id: 8,
          name: 'Sin pagar'
        },
        {
          id: 9,
          name: 'Parcialmente Pagado'
        },
        {
          id: 10,
          name: 'Pagado'
        }
      ],
      paymentStatusTemplateAll: [
        {
          id: 8,
          name: 'Sin pagar'
        },
        {
          id: 9,
          name: 'Parcialmente Pagado'
        },
        {
          id: 10,
          name: 'Pagado'
        }
      ],
      paymentStatusTemplateCredit: [
        {
          id: 8,
          name: 'Sin pagar'
        },
        {
          id: 9,
          name: 'Parcialmente Pagado'
        },
      ],
      // Templates
      itemTemplate: {
        idItem: null,
        quantity: null,
        price: null,
        idMeasurementUnit: 1,
        subTotal: null,
        subTotalCommission: 0,
        commissions: [],
        providers: [
          {
            idProvider: null,
            estimatedQuantity: null,
            deliveryQuantity: null,
            estimatedDate: null,
            deliveryDate: null,
            price: null,
            discount: 0,
            subTotal: null,
          }
        ]
      },
      // Provider 
      providerTemplate: {
        idProvider: null,
        estimatedQuantity: null,
        deliveryQuantity: null,
        estimatedDate: null,
        deliveryDate: null,
        price: null,
        subTotal: null,
      },
      // Invoice
      invoiceData: {
        idOrder: null,
        creationDate: null,
        estimatedDate: null,
        deliveryDate: null,
        creationDateString: new Date(),
        estimatedDateString: null,
        deliveryDateString: null,
        idCompany: null,
        total: null,
        discount: 0,
        tax: null,
        idCountryTax: 1,
        subTotal: null,
        idQuote: null,
        idPaymentStatus: 8,
        idShippingStatus: 5,
        idUser: null,
        paymentType: 0,
        creditDays: 0,
        deadlinePayment: null,
        deadlinePaymentString: null,
        subTotalLogisticPrice: 0,
        logisticPrice: 0,
        orderBalance: 0,
        balancesCommissions: [],
        note: null,
        items: [],
        companyData: {
          idCompany: 0,
          companyName: null,
          companyEmail: null,
          contact: null,
          country: null,
          address: null,
        },
      },
      brokerData: {
        companyName: JSON.parse(localStorage.getItem('userData')).companyName,
        address: JSON.parse(localStorage.getItem('userData')).address,
        country: JSON.parse(localStorage.getItem('userData')).country,
      },
      itemsTemplate: [],
      
      // Props
      showCreditDays: false,
      showPartialPayment: false,
      saveButtonStatus: false,
      byPassRouteLeave: true,
      orderOverlay: true,
      mailVoucherStatus: false,
      smsVoucherStatus: false,
      smsSucess: false,
      
      // 
      configTime: {
        dateFormat: 'd-m-Y',
        locale: Spanish, // locale for this instance only          
      },

      }
  },
  created() {
    this.getProviders();
  },
  methods: {
    getOrderData() {
      this.$axios.get(this.$services + 'orders/get_purchase_order', {
        params: {
          idOrder: router.currentRoute.params.id,
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        // Set de todos los datos del servicio, para visualizar en pantalla
        if (!res.data.error) {

          // Pagos y envio
          this.invoiceData.paymentType = res.data.data.paymentType;
          this.setPaymentType(this.invoiceData.paymentType);
          this.invoiceData.creditDays = res.data.data.creditDays;
          this.invoiceData.deadlinePaymentString = this.$moment(res.data.data.deadlinePaymentString).format("DD/MM/YYYY");
          this.invoiceData.idPaymentStatus = res.data.data.idPaymentStatus;
          this.setPartialPayment(this.invoiceData.idPaymentStatus);
          this.invoiceData.orderBalance = res.data.data.orderBalance;
          this.invoiceData.idShippingStatus = res.data.data.idShippingStatus;

          // idOrder
          this.invoiceData.idOrder = res.data.data.idOrder;

          // Fechas
          this.invoiceData.creationDateString = res.data.data.creationDate;
          this.invoiceData.estimatedDateString = res.data.data.estimatedDate;
          this.invoiceData.deliveryDateString = res.data.data.deliveryDate;
          // this.invoiceData.deadlinePaymentString = parseInt(res.data.data.deadlinePayment);

          // Cliente
          const companyData = this.companies.find(company => company.idCompany == res.data.data.idCompany);
          this.setClientData(companyData);

          // Items
          this.itemsTemplate = res.data.data.items;

          // Nota
          this.invoiceData.note = res.data.data.note;
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      }).finally(() => {
        this.orderOverlay = false;
      });
    },
    getProviders() {
      this.$axios.get(this.$services + 'providers/get_providers').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.companies = res.data.data;
          this.getStatus();
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      }).finally(() => {
      });
    },
    getStatus() {
      this.$axios.get(this.$services + 'general/get_status', {
        params: {
          statusName: 'PAYMENT'
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.paymentTypes = res.data.data;
          this.getOrderData();
        }
      }).catch(error => this.$verifyToken(error.response.data.data));
    },
    setClientData(value) {
      this.companyData = value;
      this.getItemsByClient(value.idCompany);
      this.invoiceData.idCompany = value.idCompany;
      this.invoiceData.companyData = value;
      this.invoiceData.items = [];
      this.addNewItem();
    },
    setCreditDays(value) {
      this.invoiceData.creditDays = value > 0 ? value : 0;
      this.invoiceData.deadlinePayment = this.$moment().add(this.invoiceData.creditDays, 'days').valueOf();
      this.invoiceData.deadlinePaymentString = this.$moment(this.invoiceData.deadlinePayment).format("DD/MM/YYYY");
    },
    setPaymentType(value) {
      this.invoiceData.paymentType = value;

      if (value == 4) {  // Crédito
        // this.paymentStatus = this.paymentStatusTemplateCredit;
        this.showCreditDays = true;
        this.invoiceData.deadlinePayment = this.$moment().add(this.invoiceData.creditDays, 'days').valueOf();
        this.invoiceData.deadlinePaymentString = this.$moment(this.invoiceData.deadlinePayment).format("DD/MM/YYYY");
        this.invoiceData.orderBalance = null;
        this.invoiceData.idPaymentStatus = 8;
        this.invoiceData.idShippingStatus = 5;
        this.invoiceData.estimatedDateString = null;
        this.invoiceData.deliveryDateString = null;
      } else {  // Contado
        // this.paymentStatus = this.paymentStatusTemplateAll;
        this.showCreditDays = false;
        this.showPartialPayment = false;
        this.invoiceData.creditDays = 0;
        this.invoiceData.deadlinePayment = null;
        this.invoiceData.deadlinePaymentString = null;
        this.invoiceData.idPaymentStatus = 10;
        this.invoiceData.idShippingStatus = 7;
        this.invoiceData.estimatedDateString = this.$moment().format("YYYY/MM/DD");
        this.invoiceData.deliveryDateString = this.$moment().format("YYYY/MM/DD");
      }
    },
    setPartialPayment(value) {
      if (value == 9) {
        this.showPartialPayment = true;
      } else {
        this.invoiceData.orderBalance = null;
        this.showPartialPayment = false;
      }
    },
    setShippingStatus(value) {
      if (value == 7) {
        this.invoiceData.estimatedDateString = this.$moment().format("DD/MM/YYYY");
        this.invoiceData.deliveryDateString = this.$moment().format("DD/MM/YYYY");
      } else {
        this.invoiceData.estimatedDateString = null;
        this.invoiceData.deliveryDateString = null;
      }
    },
    getItemsByClient(idCompany) {
      this.$axios.get(this.$services + 'general/get_metals_commissions_client', {
        params: {
          idCompany: idCompany,
          role: 'PROVEEDOR',
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        this.items = [];
        if (!res.data.error) {
          // console.log(res.data.data)
          this.items = res.data.data;

          // Set items
          this.itemsTemplate.forEach((element, index) => {
            this.invoiceData.items[index].idItem = element.idItem;
            this.invoiceData.items[index].quantity = element.quantity;
            this.invoiceData.items[index].price = element.price;
            this.getCommissions(element.idItem, index);
            if (index < this.itemsTemplate.length - 1) this.addNewItem();
          });
        }
        // console.log(res.data);
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      }).finally(() => {
        this.orderOverlay = false;
      });
    },
    getCommissions(idItem , index) {
      let item = this.items.filter(items => items.idItem == idItem);

      this.invoiceData.logisticPrice = item[0].logisticPrice;
      this.invoiceData.idQuote = item[0].idQuote;

      let commissions = 0;
      
      item[0].commissions.forEach(element => {
        commissions += element.commissionPrice;

        this.invoiceData.items[index].commissions.push(element);
      });

      // this.invoiceData.items[index].price = utils.redondeo(item[0].selectedPrice - this.invoiceData.logisticPrice - commissions - item[0].increment + item[0].decrement);
    },
    showModal() {

      if (this.checkMissingParameters()) {
        this.$bvModal.show('alertModal');
        this.saveButtonStatus = false;
        return;
      }

      this.$bvModal.show('confirmModal');
    },
    saveData() {

      // Date format
      this.invoiceData.creationDate = this.$moment(this.invoiceData.creationDateString).valueOf();
      this.invoiceData.estimatedDate = this.$moment(this.invoiceData.estimatedDateString).valueOf();
      this.invoiceData.deliveryDate = this.invoiceData.deliveryDateString ? this.$moment(this.invoiceData.deliveryDateString).valueOf() : null;
      ;

      // Items
      this.invoiceData.items.forEach((element, indexItem) => {
        // Providers by Items
        this.invoiceData.items[indexItem].providers.forEach((element, indexProvider) => {
          this.invoiceData.items[indexItem].providers[indexProvider].deliveryDate = this.$moment(element.deliveryDateString).valueOf();
          this.invoiceData.items[indexItem].providers[indexProvider].estimatedDate = this.$moment(element.estimatedDateString).valueOf();
        });
      });

      this.saveButtonStatus = true;
      if (this.invoiceData.idOrder) {
        this.updateOrder();
      } else {
        this.createOrder();
      }
    },
    createOrder() {
      this.$axios.post(this.$services+'orders/create_purchase_order', {
        invoiceData: this.invoiceData,
        isProvider: false
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.showToast('success', String('Orden guardada con exito!'));
          this.saveButtonStatus = false;
          this.invoiceData.idOrder = res.data.data.idOrder;
        } else {
        }
      }).catch(error => {
        this.showToast('danger', String(error));
        this.$verifyToken(error.response.data.data);
      }).finally(() => {
        this.saveButtonStatus = false;
      });
    },
    updateOrder() {
      this.$axios.post(this.$services+'orders/update_order', {
        brokerID: "4",
        invoiceData: this.invoiceData,
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.showToast('success', String(res.data.data));
          this.saveButtonStatus = false;
        } else {
        }
      }).catch(error => this.$verifyToken(error.response.data.data));
    },
    updateInput(indexItem, indexProvider, value, type){ // Inputs de precios y cantidades

      let inputValue = parseFloat(value); // Parse al dato del input

      if (isNaN(inputValue)) { // Input es NaN
        inputValue = 0;
      }

      // CLIENTE
      if (type === 'itemQuantity') {
        this.invoiceData.items[indexItem].quantity = inputValue;
      } else if (type === 'itemPrice') {
        console.log('entra')
        this.invoiceData.items[indexItem].price = inputValue;
      } 
      // // PROVEEDOR
      // else if (type === 'estimatedQuantity') {
      //   this.invoiceData.items[indexItem].providers[indexProvider].estimatedQuantity = inputValue;
      // } else if (type === 'price') {
      //   this.invoiceData.items[indexItem].providers[indexProvider].price = inputValue;
      // } else if (type === 'deliveryQuantity') {
      //   this.invoiceData.items[indexItem].providers[indexProvider].deliveryQuantity = inputValue;
      // }
    },
    calculatedValues(indexItem, indexCommission, type) { // Calcula el subtotal
      let value = 0;

      if (type === 'itemSubTotal') {

        value = this.invoiceData.items[indexItem].price * this.invoiceData.items[indexItem].quantity;

        value = parseFloat(value.toFixed(2));

        this.invoiceData.items[indexItem].subTotal = value;

        return "$ " + utils.numberWithCommas(value);
      } else if (type === 'providerSubTotal') {
        // value = this.invoiceData.items[indexItem].providers[indexProvider].price * this.invoiceData.items[indexItem].providers[indexProvider].deliveryQuantity;

        // value = parseFloat(value.toFixed(2));

        // this.invoiceData.items[indexItem].providers[indexProvider].subTotal = value;

        // return "$ " + utils.numberWithCommas(value);
      } else if (type === 'totalQuantityItem') {

        this.invoiceData.items[indexItem].providers.forEach(element => {
          value += element.deliveryQuantity;
        });

        return value;
      } else if (type === 'subTotal') {

        this.invoiceData.items.forEach(element => {
          value += element.subTotal;
        });

        value = parseFloat(value.toFixed(2));

        this.invoiceData.subTotal = value;

        return utils.numberWithCommas(value);
      } else if (type === 'iva') {

        this.invoiceData.items.forEach(element => {
          value += element.subTotal;
        });

        value *= 0.16;

        value = parseFloat(value.toFixed(2));

        this.invoiceData.tax = value;

        return utils.numberWithCommas(value);
      } else if (type === 'total') {

        let subtotal = 0, commissions = 0, logistic;

        // subtotal
        this.invoiceData.items.forEach(element => {
          subtotal += element.subTotal;
        });
        value += subtotal;

        // Si el pago es de contado, el total son las comisiones
        if (this.invoiceData.paymentType === 3)
          this.invoiceData.orderBalance = subtotal;

        // commissions
        this.invoiceData.items.forEach(element => {
          commissions += element.subTotalCommission;
        });
        value += commissions;

        // logisticPrice
        logistic = this.invoiceData.subTotalLogisticPrice;
        value += logistic;

        // abonado
        if (this.invoiceData.orderBalance <= this.invoiceData.subTotal)
          value -= this.invoiceData.orderBalance;
        else
          value -= this.invoiceData.subTotal;
        // value *= 1.16;

        value = parseFloat(value.toFixed(2));

        this.invoiceData.total = value;

        return utils.numberWithCommas(value);
      } else if (type === 'restante') {

        // restante
        if (this.invoiceData.orderBalance <= this.invoiceData.subTotal)
          value = this.invoiceData.subTotal - this.invoiceData.orderBalance;
        else
          value = 0;

        return utils.numberWithCommas(value);
      } else if (type === 'abonado') {

        // abonado
        value = this.invoiceData.orderBalance == null ? 0 : this.invoiceData.orderBalance;

        return utils.numberWithCommas(value);
      } else if (type === 'commission') {
      
        value = this.invoiceData.items[indexItem].commissions[indexCommission].commissionPrice * this.invoiceData.items[indexItem].quantity;

        let idCommissionAgent = this.invoiceData.items[indexItem].commissions[indexCommission].idCommissionAgent;

        let index = this.invoiceData.balancesCommissions.findIndex(balance => 
          balance.idCommissionAgent == idCommissionAgent
        );

        if (index < 0) {

          let deadline;
          let deadlineDay = this.invoiceData.items[indexItem].commissions[indexCommission].deadlineDay;

          if (this.$moment().day() >= deadlineDay)
            deadline = this.$moment().day(deadlineDay + 7).valueOf();
          else
            deadline = this.$moment().day(deadlineDay).valueOf();

          let obj = {
            idCommissionAgent: idCommissionAgent,
            deadline: deadline,
            balance: 0,
            name: this.invoiceData.items[indexItem].commissions[indexCommission].agentName
          }
          this.invoiceData.balancesCommissions.push(obj)
        }

        value = parseFloat(value.toFixed(2));

        this.invoiceData.items[indexItem].subTotalCommission = value;

        return utils.numberWithCommas(value);
      } else if (type === 'commissions') {

        this.invoiceData.items.forEach(element => {
          value += element.subTotalCommission;
        });

        value = parseFloat(value.toFixed(2));

        return utils.numberWithCommas(value);
      } else if (type === 'logistic') {

        let quantity = 0;

        this.invoiceData.items.forEach(element => {
          quantity += element.quantity;
        });

        value = this.invoiceData.logisticPrice * quantity;

        value = parseFloat(value.toFixed(2));

        this.invoiceData.subTotalLogisticPrice = value;

        return utils.numberWithCommas(value);
      } else if (type === 'balancesCommissions') {

        let idCommissionAgent = indexItem;

        this.invoiceData.items.forEach(item => {
          item.commissions.forEach(commission => {
            if (commission.idCommissionAgent == idCommissionAgent){
              value += commission.commissionPrice * item.quantity;
            }
          });
        });

        value = parseFloat(value.toFixed(2));

        let index = this.invoiceData.balancesCommissions.findIndex(balance => 
          balance.idCommissionAgent == idCommissionAgent
        );

        this.invoiceData.balancesCommissions[index].balance = value;

        return utils.numberWithCommas(value);
      } else {
        return null;
      }    
    },
    checkMissingParameters() {

      // Checar fechas
      if (
        this.invoiceData.creationDateString == null ||
        this.invoiceData.creationDateString === '' ||
        this.invoiceData.estimatedDateString == null ||
        this.invoiceData.estimatedDateString === ''
      )
        return true;

      // Checar cliente
      if (this.invoiceData.idCompany == null)
        return true;

      // Al menos un item en la orden
      if (this.invoiceData.items.length == 0)
        return true;

      // Checar item/s
      for (const item of this.invoiceData.items)
        if (
          item.idItem == null ||
          item.quantity == null ||
          item.quantity === 0 ||
          item.price == null ||
          item.price === 0
        )
          return true;

      // Checar menús inferiores
      if (
        this.invoiceData.paymentType == 0
      )
        return true;

      if (
        this.invoiceData.idPaymentStatus == 9
      )
        if (        
          this.invoiceData.orderBalance == 0 ||
          this.invoiceData.orderBalance === '' ||
          this.invoiceData.orderBalance === null
        )
        return true;

      return false;  // No falta ningún parámetro
    },
    addNewItem() {
      this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemTemplate)));
    },
    removeItem(indexItem) {
      this.invoiceData.items.splice(indexItem, 1);
    },
    detectUnsavedChange() {
      this.byPassRouteLeave = false;
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notificación',
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    restrictNumber(event) { // Sólo números pueden ser ingresados
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      let stringValue = event.target.value.toString();
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || stringValue.indexOf('.') != -1)) { // 46 is dot
        event.preventDefault();
        return;
      }
      this.byPassRouteLeave = false;
    },
    // TODO TERMINAR
    printInvoice() {
      let tempTitle = document.title;
      document.title = 'Orden_Compra_#'+this.invoiceData.idOrder+'.pdf';
      window.print();
      document.title = tempTitle;
    },
    getOrderDetails(linebreak) {

      let mailBody = 'Detalles de la orden: ' + linebreak;
      mailBody += linebreak;

      // Fechas
      mailBody += 'Fecha de creación: ' + this.$moment(this.invoiceData.creationDateString).format('YYYY-MM-DD') + linebreak;
      mailBody += 'Fecha de entrega estimada: ' + this.invoiceData.estimatedDateString + linebreak;
      if (this.invoiceData.deliveryDateString)
        mailBody += 'Fecha de entregado: ' + this.invoiceData.deliveryDateString + linebreak;
      mailBody += '____________________' + linebreak;
      mailBody += linebreak + linebreak;

      // Por cada item
      this.invoiceData.items.forEach(item => {  // %0D%0A == \n
        mailBody += this.items.find(clientItem => clientItem.id == item.id).alias + linebreak;
        mailBody += 'Cantidad: ' + utils.numberWithCommas(item.quantity) + ' kg' + linebreak;
        mailBody += 'Precio: $' + utils.numberWithCommas(item.price) + linebreak;
        mailBody += 'Subtotal: $' + utils.numberWithCommas(parseFloat((item.price * item.quantity).toFixed(2))) + linebreak;
        mailBody += linebreak;
      });
      mailBody += '____________________' + linebreak;
      mailBody += linebreak;

      // Tipo de pago
      mailBody += 'Tipo de pago: ' + this.paymentTypes.find(type => type.id == this.invoiceData.paymentType).name + linebreak;
      if (this.invoiceData.paymentType == 4)
        mailBody += 'Fecha estimada de pago: ' + this.$moment(this.invoiceData.deadlinePaymentString, 'DD/MM/YYYY').format('YYYY-MM-DD') + linebreak;

      // Estatus de pago y de envío
      mailBody += 'Estatus del pago: ' + this.paymentStatus.find(status => status.id == this.invoiceData.idPaymentStatus).name + linebreak;
      mailBody += 'Estatus del envío: ' + this.shippingStatus.find(status => status.id == this.invoiceData.idShippingStatus).name + linebreak;
      mailBody += '____________________' + linebreak;
      mailBody += linebreak;

      // Parte inferior de la nota
      mailBody += 'Subtotal: $' + utils.numberWithCommas(this.invoiceData.subTotal) + linebreak;
      if (this.invoiceData.orderBalance)
        mailBody += 'Abonado: -$' + utils.numberWithCommas(this.invoiceData.orderBalance) + linebreak;
      mailBody += 'Total: $' + utils.numberWithCommas(this.invoiceData.subTotal - this.invoiceData.orderBalance) + linebreak;
      mailBody += linebreak;

      // Nota
      if (this.invoiceData.note)
        mailBody += 'Nota: ' + this.invoiceData.note + linebreak;
      mailBody += linebreak;

      return mailBody;
    },
    sendMail() {
      // Traer detalles de la orden
      this.mailVoucherStatus = true;
      let mailBody = this.getOrderDetails('%0D%0A');

      // Abrir correo
      window.open('mailto:' + this.companyData.companyEmail + '?subject=Orden%20de%20Compra%20#' + this.invoiceData.idOrder + '&body=' + mailBody);
    },
    sendSMS() {
      // Traer detalles de la orden
      this.smsVoucherStatus = true;
      let mailBody = this.getOrderDetails('\n');
      mailBody = 'Orden de Compra #' + this.invoiceData.idOrder + '\n\n' + mailBody;

      // Mandar el mensaje
      this.$axios.post(this.$services + "messages/send_message", {
        texts: [String(mailBody)],
        numbers: ['+52' + String(this.companyData.contact)]
        }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.showToast('success', String(res.data.data));
          this.smsSucess = true;
        }
      }).catch(error => {
        console.log(error);
        this.$verifyToken(error.response.data.data);
        this.showToast('danger', String('Error al enviar SMS'));
      }).finally(() => {
        this.smsVoucherStatus = false;
      });
    },
  },
  computed: {
  },
}
</script>


<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  #add-item {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }

  .companyInfo {
    display: flex !important;
  }

  // Items
  #metal-select {
    flex: 0 0 auto;
    width: 50%;
  }

  .item-data {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  #spacer {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .item-commission {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

}
</style>
